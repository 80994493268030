import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { IEvent } from '../interfaces';
import { AmplitudeService } from '../services/amplitude.service';

@Directive({
  selector: '[amplitude]'
})
export class AmplitudeDirective implements OnInit, OnDestroy {
  @Input('amplitude') private readonly _amplitude: IEvent;

  private readonly _destroy$ = new Subject<void>();

  constructor(
    private readonly _el: ElementRef,
    private readonly _amplitudeService: AmplitudeService
  ) {}

  ngOnInit(): void {
    fromEvent(this._el.nativeElement, 'click')
      .pipe(
        tap((_) => this.logEvent(this._amplitude)),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private logEvent(event: IEvent) {
    this._amplitudeService.logEvent(event);
  }
}
